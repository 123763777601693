import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';

interface SocialMediaLink {
  name: string;
  url: string;
  icon: React.ReactElement;
}

const socialMediaLinks: SocialMediaLink[] = [
//   { name: 'Facebook', url: 'https://facebook.com/autopod', icon: <FaFacebook /> },
  { name: 'Twitter', url: 'https://twitter.com/autopod_', icon: <FaTwitter /> },
//   { name: 'Instagram', url: 'https://instagram.com/autopod', icon: <FaInstagram /> },
  { name: 'LinkedIn', url: 'https://www.linkedin.com/company/autopod-audio-platform', icon: <FaLinkedin /> },
  { name: 'YouTube', url: 'https://youtube.com/@AutoPodListenAgain', icon: <FaYoutube /> },
];

interface SocialMediaLinksProps {
  className?: string;
}

const SocialMediaLinks: React.FC<SocialMediaLinksProps> = ({ className = '' }) => {
  return (
    <div className={`flex space-x-4 ${className} justify-center my-5`}>
      {socialMediaLinks.map((link) => (
        <a
          key={link.name}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-white-600 hover:text-blue-600 transition-colors duration-300"
          aria-label={`Follow us on ${link.name}`}
        >
          {React.cloneElement(link.icon, { className: 'w-6 h-6' })}
        </a>
      ))}
    </div>
  );
};

export default SocialMediaLinks;