import React, { useState } from 'react';

interface VideoDemoProps {
  videoUrl: string;
  title: string;
  description: string;
}

const VideoDemo: React.FC<VideoDemoProps> = ({ videoUrl, title, description }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <section className="bg-white-100">
      <div className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-8">{title}</h2>
        <div className="max-w-4xl mx-auto h-[30rem] flex flex-col">

<div className="relative flex-grow overflow-hidden">
            {isPlaying ? (
              <iframe
                src={`${videoUrl}?autoplay=1`}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                className="w-full h-full"
              ></iframe>
            ) : (
              <div
                className="w-full h-full bg-black flex items-center justify-center cursor-pointer"
                onClick={togglePlay}
              >
                <svg className="w-20 h-20 text-white" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M6.3 2.841A1.5 1.5 0 004 4.11V15.89a1.5 1.5 0 002.3 1.269l9.344-5.89a1.5 1.5 0 000-2.538L6.3 2.84z" />
                </svg>
              </div>
            )}
          </div>
          <p className="text-center text-gray-600 mt-4">{description}</p>
        </div>
      </div>
    </section>
  );
};

export default VideoDemo;