import React from 'react';

const WhatIsAutoPod: React.FC = () => {
  return (
    <section className="py-16 bg-green-300">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">What is AutoPod?</h2>
        <div className=" mx-auto">
          <p className="text-lg mb-6">
            AutoPod is an innovative platform that transforms your radio station into an on-demand podcast powerhouse. It seamlessly records your live broadcasts 24/7 and automatically converts them into on demand episodes, making your content available to listeners anytime, anywhere.
          </p>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-3">For Broadcasters</h3>
              <ul className="list-disc list-inside space-y-2">
                <li>Automate podcast creation from live broadcasts</li>
                <li>Expand audience reach beyond traditional airwaves</li>
                <li>Save time and resources on content repurposing</li>
              </ul>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-3">For Presenters</h3>
              <ul className="list-disc list-inside space-y-2">
                <li>Download and Keep a copy of their shows</li>
                <li>Share to social media and increase audiences</li>
                <li>Enhance their online presence</li>
              </ul>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-3">For Listeners</h3>
              <ul className="list-disc list-inside space-y-2">
                <li>Access favorite radio shows on-demand</li>
                <li>Never miss an episode, even when busy</li>
                <li>Enjoy content across multiple devices and platforms</li>
              </ul>
            </div>
          </div>
          <p className="text-lg mt-6">
            With AutoPod, you're not just broadcasting; you're creating a library of on-demand content that keeps your audience engaged long after the initial airing. It's radio reimagined for the digital age.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhatIsAutoPod;