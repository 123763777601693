import React from 'react';

interface Customer {
  name: string;
  logoUrl: string;
  websiteUrl: string;
}

interface CustomerShowcaseProps {
  customers: Customer[];
}

const CustomerShowcase: React.FC<CustomerShowcaseProps> = ({ customers }) => {
  return (
    <section className="py-16 bg-green-300">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">Trusted by stations Worldwide</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {customers.map((customer, index) => (
            <a 
              key={index}
              href={customer.websiteUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
            >
              <img 
                src={customer.logoUrl} 
                alt={`${customer.name} logo`}
                className="max-w-full max-h-16 object-contain"
              />
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CustomerShowcase;