import React, {useState} from 'react';
import VideoDemo from './videodemo';
import WhatIsAutoPod from './whatisautopod';
import CustomerShowcase from './customershowcase';
import SocialMediaLinks from './socials';



const KeyFeatures = () => (
  <section className="py-16 bg-blue-200">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-10">Key Features</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {['24/7 Automatic Recording & Podcast Creation',
         'Smart Scheduling & Content Management', 
         'Multi-Platform Distribution', 
         'Presenter Accounts to download shows',
         'Embedded player and widgets for your website',
          'Detailed Analytics & Listener Insights'].map((feature, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="font-semibold text-lg mb-2">{feature}</h3>
          </div>
        ))}
      </div>
    </div>
  </section>
);



const Benefits = () => (
  <section className="py-16">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-10">Why Choose AutoPod?</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {[
          'Expand Your Audience: Reach listeners beyond your broadcast hours',
          'Save Time: Automate podcast creation and distribution',
          'Increase Engagement: Offer on-demand content to your audience',
          'Boost Discoverability: Optimize your content for podcast platforms',
          'Retain Control: Full ownership and management of your content',
          'Publish to 3rd Party Platforms: Distribute your content to Apple Podcasts, Spotify, and more',

        ].map((benefit, index) => (
          <div key={index} className="flex items-start">
            <svg className="w-6 h-6 text-green-500 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
            <p>{benefit}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

const HowItWorks = () => (
  <section className="py-16 bg-gray-100">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-10">How AutoPod Works</h2>
      <div className="flex flex-col md:flex-row justify-between items-center">
        {[
          'Record: AutoPod captures your live broadcasts 24/7',
          'Process: Our Recorders save your audio to the cloud',
          'Publish: Episodes are created based on your custom schedule',
          'Distribute: Content is shared across multiple platforms',
          'Analyze: Track listenership and gain listener insights'
        ].map((step, index) => (
          <div key={index} className="flex flex-col items-center mb-6 md:mb-0">
            <div className="w-12 h-12 bg-blue-600 text-white rounded-full flex items-center justify-center text-xl font-bold mb-4">{index + 1}</div>
            <p className="text-center max-w-xs">{step}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

const Pricing = () => (
  <section className="py-16 bg-gray-100">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-10">Flexible Pricing Plans</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {[
          { name: 'AutoPod 14', retention: '14-day content retention', price: '£19.99/month' },
          { name: 'AutoPod 90', retention: '90-day content retention', price: '£29.99/month' },
          { name: 'AutoPod 365', retention: '365-day content retention', price: '£39.99/month' }
        ].map((plan, index) => (
          <div key={index} className="bg-white p-8 rounded-lg shadow-md text-center">
            <h3 className="text-2xl font-bold mb-4">{plan.name}</h3>
            <p className="mb-4">{plan.retention}</p>
            <p className="text-3xl font-bold mb-6">{plan.price}</p>
            <a 
      href="https://my.hippynet.co.uk/index.php?rp=/store/autopod" 
      target="_blank" 
      rel="noopener noreferrer" 
      className="inline-block bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition duration-300"
    >
      Choose Plan
    </a>
          </div>
        ))}
      </div>
      <p className="text-center mt-8">All plans include full feature access. Choose based on your content retention needs.</p>
    </div>
  </section>
);


interface Product {
  imageUrl: string;
  title: string;
  description: string;
}

interface ProductShowcaseProps {
  products: Product[];
}

const ProductShowcase: React.FC<ProductShowcaseProps> = ({ products }) => {
  const [fullscreenImage, setFullscreenImage] = useState<string | null>(null);

  const openFullscreen = (imageUrl: string) => {
    setFullscreenImage(imageUrl);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        {/* <h2 className="text-3xl font-bold text-center mb-10">AutoPod in Action</h2> */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {products.map((product, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
              <img 
                src={product.imageUrl} 
                alt={product.title} 
                className="w-full h-72 object-cover cursor-pointer transition duration-300 hover:opacity-80"
                onClick={() => openFullscreen(product.imageUrl)}
              />
              <div className="p-4">
                <h3 className="font-semibold text-lg mb-2">{product.title}</h3>
                <p className="text-gray-600">{product.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {fullscreenImage && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50"
          onClick={closeFullscreen}
        >
          <div className="relative max-w-7xl max-h-full p-4">
            <img 
              src={fullscreenImage} 
              alt="Fullscreen view" 
              className="max-w-full max-h-full object-contain"
            />
            <button 
              className="absolute top-4 right-4 text-white text-2xl"
              onClick={closeFullscreen}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </section>
  );
};



const CTA = () => (
  <section className="bg-black text-white py-16">
    <div className="container mx-auto text-center px-4">
      <h2 className="text-3xl font-bold mb-4">Ready to untether your content?</h2>
      <a 
      href="https://my.hippynet.co.uk/index.php?rp=/store/autopod" 
      target="_blank" 
      rel="noopener noreferrer" 
      className="inline-block bg-white text-blue-600 px-8 py-3 rounded-full text-lg font-semibold hover:bg-blue-100 transition duration-300"
    >
      Start Your Free Trial
    </a>
    
      <p><br></br>Experience the full power of AutoPod for 30 days.</p>

     <SocialMediaLinks/>

    </div>
  </section>
);

const productData: Product[] = [
  {
    imageUrl: "/dashboard.png",
    title: "Intuitive Dashboard",
    description: "Manage your podcasts with ease using our user-friendly dashboard."
  },
  {
    imageUrl: "spotify.png",
    title: "RSS Feed",
    description: "Use your podcast RSS feed to distribute your content to multiple platforms."
  },
  {
    imageUrl: "/widgets.png",
    title: "Embeddable Widgets",
    description: "A wide variety of widgets to embed on your website in a style that suits you."
  },
  
  {
    imageUrl: "statistics.png",
    title: "Detailed Analytics",
    description: "Gain valuable insights into your audience with comprehensive analytics."
  },

  {
    imageUrl: "player.png",
    title: "AutoPod Player",
    description: "Includes the AutoPod Player for a seamless listening experience. Including search, sharing and more"
  },
  {
    imageUrl: "generic.png",
    title: "Full Show Length",
    description: "Our recordings are not capped in hourly increments, your whole episode is one piece of content"
  },
  // Add more products as needed
];
const customers = [
  {
    name: "Go Radio",
    logoUrl: "https://thisisgo.co.uk/wp-content/uploads/2023/04/go-logo.png",
    websiteUrl: "https://thisisgo.co.uk/listen-again/"
  },
  {
    name: "Seahaven FM",
    logoUrl: "https://mmo.aiircdn.com/892/63854c57a853f.png",
    websiteUrl: "https://www.seahavenfm.radio/onair/listen-again/"
  },

  {
    name: "Rossendale Radio",
    logoUrl: "https://s3.eu-central-1.wasabisys.com/autopod-public/images/HV0ZFO8QXV9KS4Z9X7LX8PFQZQTJ3L.png",
    websiteUrl: "https://www.rossendaleradio.com/listen-again1/"
  },
  {
    name: "Spark Sunderland",
    logoUrl: "https://www.sparksunderland.com/wp-content/uploads/2018/05/Spark-Logo-Text-Orange-test-1.png",
    websiteUrl: "https://www.sparksunderland.com/listenagain/"
  },
  {
    name: "Fix Radio",
    logoUrl: "https://www.installershow.com/wp-content/uploads/2021/08/fix-radio-logo.jpg",
    websiteUrl: "https://www.fixradio.co.uk/listen/on-demand/"
  },
  {
    name: "CHMA (Canada)",
    logoUrl: "https://th.bing.com/th/id/OIP.SjKbz2pmo_HlgBEsFdGd-AAAAA?rs=1&pid=ImgDetMain",
    websiteUrl: "https://www.chmafm.com/welcome/on-demand/"
  },
  {
    name: "K107",
    logoUrl: "https://s3.eu-central-1.wasabisys.com/autopod-public/images/k107.png",
    websiteUrl: "https://www.k107.co.uk/listen-again"
  },
  {
    name: "Beyond Radio",
    logoUrl: "https://mmo.aiircdn.com/458/61aa61331c3ce.png",
    websiteUrl: "https://www.beyondradio.co.uk/on-air/listen-again/"
  },
  // ... add more customers as needed
];

const AutoPodContent = () => (
  <div className="autopod-page">
    <ProductShowcase products={productData}/>
    <WhatIsAutoPod />
    <VideoDemo videoUrl="https://www.youtube.com/embed/3781jxSeIK0"
        title="AutoPod Dashboard Overview"
        description="A quick video showing you parts of the control panel for AutoPod and some of the basic functionality."/>
    <HowItWorks />
    <KeyFeatures />
    <Benefits />
    <CustomerShowcase customers={customers}/>
    <Pricing />
    <CTA />

  </div>
);

export default AutoPodContent;