import React, { useState, useEffect } from 'react';


const phrases = [
  "Radio Stations",
  "Podcasters",
  "Broadcasters",
  "Content Creators",
  "Audio Enthusiasts"
];

const fonts = [
  '"Press Start 2P", cursive',
  '"VT323", monospace',
  '"Orbitron", sans-serif',
  '"Audiowide", cursive',
  '"Chakra Petch", sans-serif'
];

const Hero = () => {
  const [phraseIndex, setPhraseIndex] = useState(0);
  const [currentText, setCurrentText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentFont, setCurrentFont] = useState(fonts[0]);

  useEffect(() => {
    const currentPhrase = phrases[phraseIndex];
    
    const timer = setTimeout(() => {
      if (!isDeleting && currentText === currentPhrase) {
        setTimeout(() => setIsDeleting(true), 1500);
      } else if (isDeleting && currentText === '') {
        setIsDeleting(false);
        setPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        setCurrentFont(fonts[(phraseIndex + 1) % fonts.length]);
      } else if (isDeleting) {
        setCurrentText(currentPhrase.substring(0, currentText.length - 1));
      } else {
        setCurrentText(currentPhrase.substring(0, currentText.length + 1));
      }
    }, isDeleting ? 50 : 100);

    return () => clearTimeout(timer);
  }, [currentText, isDeleting, phraseIndex]);

  return (
    <section className="bg-gradient-to-r from-blue-600 to-purple-600 text-white py-20">
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=VT323&family=Orbitron&family=Audiowide&family=Chakra+Petch&display=swap');
        `}
      </style>
      <div className="container mx-auto text-center px-4">
        <h1 className="text-4xl md:text-6xl font-bold mb-4">
         Your Radio Station, On Demand

        </h1>
        <p className="text-xl md:text-2xl mb-6">
        </p>
        {/* <div className="h-20 flex items-center justify-center mb-6">
          <h2 className="text-2xl md:text-4xl font-bold">
            Perfect for{" "}
            <span style={{
              fontFamily: currentFont,
              transition: 'font-family 0.3s ease'
            }}>
              {currentText}
            </span>
            <span className="animate-pulse">|</span>
          </h2>
        </div> */}
        <ul className="text-lg md:text-xl mb-8 space-y-2">
          <li>✓ 24/7 Automatic Recording & Podcast Creation</li>
          <li>✓ Multi-Platform Distribution (Website, Apple Podcasts, Spotify)</li>
          <li>✓ Fully online, no equipment required</li>
        </ul>
        <a 
      href="https://my.hippynet.co.uk/index.php?rp=/store/autopod" 
      target="_blank" 
      rel="noopener noreferrer" 
      className="inline-block bg-white text-blue-600 px-8 py-3 rounded-full text-lg font-semibold hover:bg-blue-100 transition duration-300"
    >
      Start Your Free Trial
    </a>
        <p className="mt-4 text-sm">30-day free trial. use code AutoPodTrial when signing up.</p>
      </div>
    </section>
  );
};

export default Hero;