import React from 'react';
import TypewriterHero from './components/herosection';
import AutoPodContent from './components/autopodcontent';
import Metadata from './components/metadata';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ResellerPage from './components/reselletpage';
import SocialMediaLinks from './components/socials';
import FeatureGridPage from './components/featuregrid';

// Header Component
const Header = () => (
  <header className="bg-gray-800 text-white p-4">
    <div className="container mx-auto flex justify-between items-center">
      <img alt='logo' className="mx-left w-32  " src="/logo.png"/>
      <Navigation />
    </div>
  </header>
);

// Navigation Component
const Navigation = () => (
  <nav>
    <ul className="flex space-x-8">
      <li><a href="https://www.hippynet.co.uk/knowledgebase/autopod/" target="_blank" className="hover:underline">Knowledgebase</a></li>
      <li><a href="https://dashboard.autopod.xyz" target="_blank" className="hover:underline">Sign In</a></li>

    </ul>
  </nav>
);

// Hero Section Component
const HeroSection = () => (
    <TypewriterHero/>
);

// Main App Component
const HomePage = () => {
  return (
    <div>
      <Metadata/>
      <HeroSection />
      <AutoPodContent/>
    </div>
  );
};


const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* <Route path="/features" element={<FeatureGridPage />} />
          <Route path="/reseller" element={<ResellerPage />} /> */}
        </Routes>
      </div>
    </Router>
  );
};


export default App;