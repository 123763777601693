import React from 'react';
import { Helmet } from 'react-helmet';

interface MetadataProps {
  title?: string;
  description?: string;
  keywords?: string;
  ogImage?: string;
}

const Metadata: React.FC<MetadataProps> = ({
  title = 'AutoPod - Your Radio Station, On Demand',
  description = 'AutoPod transforms your radio broadcasts into on-demand podcasts. Expand your audience reach and never let your listeners miss a show.',
  keywords = 'autopod, radio, podcast, on-demand audio, broadcast',
  ogImage = ''
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.autopod.xyz/" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://www.autopod.xyz/" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={ogImage} />

      {/* Additional metadata */}
      <link rel="canonical" href="https://www.autopod.xyz/" />
    </Helmet>
  );
};

export default Metadata;